<template>
  <div class="el-collapse-box">
    <el-card class="card-item">
      <div class="title">
        基本信息
      </div>
      <div class="divider"></div>
      <div class="content">
        <div class="content-item">
          <span style="color:red">*</span>
          保单号：{{ detailData.policyNo }}
        </div>
        <div class="content-item">
          起保时间：{{ detailData.startDate }}
        </div>
        <div class="content-item">
          到保时间：{{ detailData.endDate }}
        </div>
        <div class="content-item">
          总保费：{{ detailData.totalPremium }}
        </div>
        <div class="content-item">
          总保额：{{ detailData.totalAmount }}
        </div>
        <div class="content-item">
          业务员：{{ detailData.salesmanName }}
        </div>
        <div class="content-item">
          添加时间：{{ detailData.createTime }}
        </div>
        <div class="content-item">
          更新时间：{{ detailData.updateTime }}
        </div>
        <div class="content-item">
          处理状态：{{ detailData.dealStatus ? '已处理' : '未处理' }}
        </div>
        <div class="content-item">
          处理时间：{{ detailData.dealTime }}
        </div>  
        <div class="content-item">
          处理人：{{ detailData.dealUser }}
        </div>
        <div class="content-item">
          录单员：{{ detailData.recorderName }}
        </div>
      </div>
    </el-card>
    <el-card class="card-item">
      <div class="title">
        被保险人
      </div>
      <div class="divider"></div>
      <div class="content"
        v-for="item,index in detailData.policyInsuredList"
        :key="index">
        <div class="content-item">
          <span style="color:red">*</span>
          被保险人：{{ item.playersName }}
        </div>
        <div class="content-item">
          联系电话：{{ item.contactPhone }}
        </div>
        <div class="divider2"
          v-if="detailData.policyInsuredList.length - 1 > index"></div>
      </div>
    </el-card>
    <el-card class="card-item">
      <div class="title">
        标的信息
      </div>
      <div class="divider"></div>
      <div class="content"
        v-for="item,index in detailData.policySubjectList"
        :key="index">
        <div class="content-item">
          设备型号：{{ item.equipmentType }}
        </div>
        <div class="content-item">
          车牌号：{{ item.plate }}
        </div>
        <div class="content-item">
          车架号：{{ item.frameNo }}
        </div>
        <div class="divider2"
          v-if="detailData.policyInsuredList.length - 1 > index"></div>
      </div>
    </el-card>
    <el-card class="card-item">
      <div class="title">
        选择客户
      </div>
      <div class="divider"></div>
      <div class="content">
        <div class="divider2"></div>
        <el-table :data="detailData.customerList"
          border>
          <el-table-column label="用户昵称"
            prop="userName"></el-table-column>
          <el-table-column label="手机号"
            prop="phoneNum"></el-table-column>
          <el-table-column label="推荐人"
            prop="referrerName"></el-table-column>
          <el-table-column label="用户类型"
            prop="customerTypeDesc"></el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "CorrectionItemDetail",
  props: {
    detailData: {
      type: Object,
      default: () => { }
    },
  },
  data() {
    return {
      dataType: "object",
    };
  },
};
</script>

<style lang="scss" scoped>
.card-item {
  background: #ffffff;
  border-radius: 8px;
  opacity: 1;
  display: column;
  margin-top: 10px;

  .title {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: 0px;

    &::before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 24px;
      background: #4278c9;
      border-radius: 20px 4px 4px 20px;
      opacity: 1;
      margin: 0 8px 0 4px;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    margin: 12px 4px 0 4px;
    background-image: linear-gradient(
      to right,
      #ccc 0%,
      #ccc 50%,
      transparent 50%
    );
    background-size: 15px 1px;
    background-repeat: repeat-x;
  }

  .divider2 {
    width: 100%;
    height: 20px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;

    .content-item {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      margin: 24px 0 0 18px;
      min-width: 300px;
    }
  }
}

.el-collapse-box {
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    // overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__body {
    tr {
      position: relative;
    }
    // .table-del {
    //   position: absolute;
    //   top: 50%;
    //   right: -50px;
    //   transform: translateY(-50%);
    //   font-size: 30px;
    //   color: #c94242;
    //   cursor: pointer;
    // }
    .el-form-item {
      margin: 0;
    }
  }
  .plan-table {
    .has-gutter,
    .el-table__fixed-header-wrapper {
      tr {
        overflow: hidden;
      }
    }
    .el-table__body-wrapper {
      overflow: visible;
    }
  }
}
</style>
