<template>
  <div class="shell-box">
    <el-breadcrumb separator="/"
      class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/myHome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/routerPolicyManagement/policyHosting' }">保单托管</el-breadcrumb-item>
      <el-breadcrumb-item>托管详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="form">
      <policyDetails :detailData="policyDetail" />
    </div>
    <div class="operating-box">
      <el-button size="mini"
        @click="handleBack">返回</el-button>
      <el-button size="mini"
        type="primary"
        plain
        @click="handleCreateCorrectionOrder">编辑</el-button>
      <el-button size="mini"
        type="primary"
        @click="showUpload= true">查看附件</el-button>
    </div>
    <DragMultipleUpload :show.sync="showUpload"
      :dataEcho="policyDetail.attachments.bdtg" :fileSzie="10"
      :isClickUpload="false"
      :canEdit="false"></DragMultipleUpload>
  </div>
</template>

<script>
import policyDetails from "@/views/policyManagement/components/Hosting/details.vue";
import { trusteeshipDetails, getUpdateRecord, getPolicyDetail } from "@/api/policy";
import DragMultipleUpload from '@/views/policyManagement/components/Hosting/attachment.vue';
import { hasPerms, dataPermissions } from "@/utils/auth";
export default {
  components: { policyDetails, DragMultipleUpload },
  data() {
    return {
      policyDetail: {
        attachments: {
          bdtg: []
        }
      },
      updateRecordDetail: [],
      showUpload: false,
      dataEcho: {},

      policyDetailPermissions: {
        deductibleTwo: dataPermissions('M20-View', 'deductibleTwo'),
        // "desc": "扩展不计免赔2（绝对免赔1000元）",
        endDate: dataPermissions('M20-View', 'endDate'),
        // "desc": "到期时间",
        policyNo: dataPermissions('M20-View', 'policyNo'),
        // "desc": "保单号",
        createUserName: dataPermissions('M20-View', 'createUserName'),
        // "desc": "录单员",
        absoluteExemptionPerAccident: dataPermissions('M20-View', 'absoluteExemptionPerAccident'),
        // "desc": "每次事故绝对免陪额",
        fromSourceType: dataPermissions('M20-View', 'fromSourceType'),
        // "desc": "保单来源",
        policyState: dataPermissions('M20-View', 'policyState'),
        // "desc": "保单状态",
        province: dataPermissions('M20-View', 'province'),
        // "desc": "所属省份",
        renewalState: dataPermissions('M20-View', 'renewalState'),
        // "desc": "续保状态",
        acceptCompanyList: dataPermissions('M20-View', 'acceptCompanyList'),
        // "desc": "承保公司",
        specialAgreement: dataPermissions('M20-View', 'specialAgreement'),
        // "desc": "特别约定",
        salesmanName: dataPermissions('M20-View', 'salesmanName'),
        // "desc": "业务员",
        brandName: dataPermissions('M20-View', 'brandName'),
        // "desc": "设备品牌",
        salesmanId: dataPermissions('M20-View', 'salesmanId'),
        // "desc": "业务员",
        beneficiaryList: dataPermissions('M20-View', 'beneficiaryList'),
        // "desc": "受益人",
        policyHolderList: dataPermissions('M20-View', 'policyHolderList'),
        // "desc": "投保人",
        schemeName: dataPermissions('M20-View', 'schemeName'),
        // "desc": "方案名称",
        agentName: dataPermissions('M20-View', 'agentName'),
        // "desc": "代理人",
        ep: dataPermissions('M20-View', 'ep'),
        // "desc": "电子保单",
        sort: dataPermissions('M20-View', 'sort'),
        // "desc": "排序",
        policyPlanList: dataPermissions('M20-View', 'policyPlanList'),
        // "desc": "保障计划",
        insuranceName: dataPermissions('M20-View', 'insuranceName'),
        // "desc": "险种名称",
        scopeOfApplication: dataPermissions('M20-View', 'scopeOfApplication'),
        // "desc": "适用范围",
        absoluteDeductible: dataPermissions('M20-View', 'absoluteDeductible'),
        // "desc": "扩展不计免赔1",
        policyInsuredList: dataPermissions('M20-View', 'policyInsuredList'),
        // "desc": "被保险人",
        projectName: dataPermissions('M20-View', 'projectName'),
        // "desc": "项目名称",
        startDate: dataPermissions('M20-View', 'startDate'),
        // "desc": "起保时间",
        status: dataPermissions('M20-View', 'status'),
        // "desc": "保单状态",
        frameNo: dataPermissions('M20-View', 'frameNo'),
        // "desc": "车架号",
        agentId: dataPermissions('M20-View', 'agentId'),
        // "desc": "代理人",
        applicantMode: dataPermissions('M20-View', 'applicantMode'),
        // "desc": "投保方式",
        lastPolicyNo: dataPermissions('M20-View', 'lastPolicyNo'),
        // "desc": "上一年保单号",
        insured: dataPermissions('M20-View', 'insured'),
        // "desc": "被保险人",
        city: dataPermissions('M20-View', 'city'),
        // "desc": "所属地区",
        equipmentType: dataPermissions('M20-View', 'equipmentType'),
        // "desc": "设备型号",
        newPolicyNo: dataPermissions('M20-View', 'newPolicyNo'),
        // "desc": "新保单号",
        applicantType: dataPermissions('M20-View', 'applicantType'),
        // "desc": "投保类型",
        statusName: dataPermissions('M20-View', 'statusName'),
        // "desc": "保单状态",
        freeExtensionTerms: dataPermissions('M20-View', 'freeExtensionTerms'),
        // "desc": "免费扩展条款",
        extensionClause: dataPermissions('M20-View', 'extensionClause'),
        // "desc": "免费扩展条款",
        insuranceCompany: dataPermissions('M20-View', 'insuranceCompany'),
        // "desc": "保险公司",
        applicantStore: dataPermissions('M20-View', 'applicantStore'),
        // "desc": "投保门店",
        absoluteDeductible3: dataPermissions('M20-View', 'absoluteDeductible3'),
        // "desc": "未扩展不计免赔",
        absoluteDeductible2: dataPermissions('M20-View', 'absoluteDeductible2'),
        // "desc": "扩展不计免赔2",
        business: dataPermissions('M20-View', 'business'),
        // "desc": "业务员",
        agentNo: dataPermissions('M20-View', 'agentNo'),
        // "desc": "代理人编码",
        deductibleOne: dataPermissions('M20-View', 'deductibleOne'),
        // "desc": "扩展不计免赔1（0免赔）",
        totalPremium: dataPermissions('M20-View', 'totalPremium'),
        // "desc": "总保费",
        updateTime: dataPermissions('M20-View', 'updateTime'),
        // "desc": "更新时间",
        projectRemark: dataPermissions('M20-View', 'projectRemark'),
        // "desc": "项目备注",
        insuranceCompanyName: dataPermissions('M20-View', 'insuranceCompanyName'),
        // "desc": "保险公司",
        createTime: dataPermissions('M20-View', 'createTime'),
        // "desc": "创建时间",
        fromSourceTypeName: dataPermissions('M20-View', 'fromSourceTypeName'),
        // "desc": "保单来源",
        policySubjectList: dataPermissions('M20-View', 'policySubjectList'),
        // "desc": "标的信息",
        insuranceTypeName: dataPermissions('M20-View', 'insuranceTypeName'),
        // "desc": "险种名称",
        playersHolder: dataPermissions('M20-View', 'playersHolder'),
        // "desc": "投保人",
        enquiryNo: dataPermissions('M20-View', 'enquiryNo'),
        // "desc": "新询价单号",
        policyHolder: dataPermissions('M20-View', 'policyHolder'),
        // "desc": "投保人",
        deductibleExpanded: dataPermissions('M20-View', 'deductibleExpanded'),
        // "desc": "未扩展不计免赔",
        recorder: dataPermissions('M20-View', 'recorder'),
        // "desc": "录单员",

        insuranceOfDate: dataPermissions('M20-View', 'insuranceOfDate'),

        applicantType: dataPermissions('M20-View', 'applicantType'),

        applicantTypeName: dataPermissions('M20-View', 'applicantTypeName'),
        specialVersion: dataPermissions('M20-View', 'specialVersion'),
        // "desc": "客户",
        bindUser: dataPermissions('M20-View', 'bindUser'),
      }
    };
  },
  created() {
    this.$route.query.id ? this.getDetail() : '';
    // this.policyDetail = JSON.parse(this.$route.query.detailData);
    this.policyDetail.customerInfo ? this.policyDetail.customerList = [JSON.parse(this.policyDetail.customerInfo)] : '';
    // this.policyDetail.attachment ? this.policyDetail.attachment = JSON.parse(this.policyDetail.attachment) : '';
    // this.policyDetail.attachment && this.policyDetail.attachment != "{}"  ? this.changeAttachment(JSON.parse(this.policyDetail.attachment)) : '';
  },
  activated() {
      this.$route.query.id ? this.getDetail() : '';
    this.policyDetail.customerInfo ? this.policyDetail.customerList = [JSON.parse(this.policyDetail.customerInfo)] : '';
  },
  methods: {
    changeAttachment(attachment) {
      let dataBackShow = [];
      let idInd = 1;
      attachment ? Object.keys(attachment).forEach(function (key) {
        let type = (key + '').split('-');
        dataBackShow.push({
          type: type[0] == 'qt' ? undefined : type[0],
          selectValue: Number(type[1]),
          urls: attachment[key].map(val => {
            idInd++;
            return {
              suffix: val.fileType,
              name: val.fileName,
              url: val.url,
              id: idInd
            };
          })
        });
      }) : '';
      this.dataEcho = dataBackShow;
      this.$forceUpdate();
    },
    getDetail() {
      getPolicyDetail({
        id: this.$route.query.id
      }).then(res => {
        res.data.customerList = [];
        res.data.customerList = res.data.bindUserId ? [{ userId: res.data.bindUserId, userName: res.data.bindUserName, phoneNum: res.data.bindUserPhone, customerTypeDesc: res.data.customerTypeDesc, referrerName: res.data.referrerName }] : [];
        this.policyDetail = res.data;
        //   this.policyDetail.customerInfo ? this.policyDetail.customerList = [JSON.parse(this.policyDetail.customerInfo)] : '';
        this.$forceUpdate();
      }).catch(err => {
        console.log(err);
      });
    },
    handleBack() {
      this.$router.go(-1);
    },
    handleCreateCorrectionOrder(e) {
      this.$router.push({
        path:
          "/routerPolicyManagement/hostingEdit?id=" + this.$route.query.id,
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.shell-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  .form {
    flex: 1;
    overflow: hidden;
  }
  .breadcrumb {
    padding: 10px 0;
    ::v-deep .el-breadcrumb__inner.is-link {
      color: rgba(0, 128, 255, 1);
    }
  }
  .operating-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    padding: 0 24px;
    background: #ffffff;
    box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    z-index: 99;
  }
}
</style>